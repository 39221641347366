<script setup lang="ts">
import Typewriter from 'typewriter-effect/dist/core'

const { strings, loop = false, delay = 30, deleteSpeed = 5, startPaused = false } = defineProps<{
  strings: string[]
  loop?: boolean
  delay?: number
  deleteSpeed?: number
  startPaused?: boolean
}>()

const root = ref<HTMLElement | null>(null)
const typeWriter = ref<Typewriter | null>(null)

function beginAnimation() {
  if (typeWriter.value)
    return

  typeWriter.value = new Typewriter(root.value, {
    strings,
    loop,
    delay,
    deleteSpeed,
  })

  strings.forEach((string) => {
    typeWriter.value.typeString(string).pauseFor(2500)

    if (strings.indexOf(string) === strings.length - 1)
      typeWriter.value.start()
    else
      typeWriter.value.deleteAll()
  })
}

onMounted(() => {
  if (!startPaused)
    beginAnimation()
})

defineExpose({
  beginAnimation,
})
</script>

<template>
  <div ref="root" />
</template>
